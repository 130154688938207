<h1 mat-dialog-title translate>vehicles.{{data.op}}.code</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>Contesto</mat-label>
      <mat-select [(ngModel)]="data.context">
        <mat-option value="internal">{{'vehicle.internal'|translate}}</mat-option>
        <mat-option value="qrcode">QRCode 1</mat-option>
        <mat-option value="qrcode2">QRCode 2</mat-option>
        <mat-option value="qrcode3">QRCode 3</mat-option>
        <mat-option value="qrcode4">QRCode 4</mat-option>

        <mat-option value="flespi">Flespi Code</mat-option>
        <mat-option value="segwayDiscovery">Segway Discovery Code</mat-option>
        <mat-option value="linka">Linka Code</mat-option>
        <mat-option value="axa">AXA Code</mat-option>
        <mat-option value="evolvo">Evolvo Code</mat-option>
        <mat-option value="invers">Invers Code</mat-option>

      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Codice</mat-label>
      <input matInput [(ngModel)]="data.code">
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
