import { Injectable } from '@angular/core';
import { AnyMxRecord } from 'dns';

const CRCHi = [
  0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40, 0x00, 0xC1, 0x81, 0x40,
0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0, 0x80, 0x41, 0x00, 0xC1,
0x81, 0x40, 0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41,
0x00, 0xC1, 0x81, 0x40, 0x01, 0xC0, 0x80, 0x41, 0x01, 0xC0,
0x80, 0x41, 0x00, 0xC1, 0x81, 0x40
  ];

const CRCLo = [
  0x00, 0xC0, 0xC1, 0x01, 0xC3, 0x03, 0x02, 0xC2, 0xC6, 0x06,
0x07, 0xC7, 0x05, 0xC5, 0xC4, 0x04, 0xCC, 0x0C, 0x0D, 0xCD,
0x0F, 0xCF, 0xCE, 0x0E, 0x0A, 0xCA, 0xCB, 0x0B, 0xC9, 0x09,
0x08, 0xC8, 0xD8, 0x18, 0x19, 0xD9, 0x1B, 0xDB, 0xDA, 0x1A,
0x1E, 0xDE, 0xDF, 0x1F, 0xDD, 0x1D, 0x1C, 0xDC, 0x14, 0xD4,
0xD5, 0x15, 0xD7, 0x17, 0x16, 0xD6, 0xD2, 0x12, 0x13, 0xD3,
0x11, 0xD1, 0xD0, 0x10, 0xF0, 0x30, 0x31, 0xF1, 0x33, 0xF3,
0xF2, 0x32, 0x36, 0xF6, 0xF7, 0x37, 0xF5, 0x35, 0x34, 0xF4, 
0x3C, 0xFC, 0xFD, 0x3D, 0xFF, 0x3F, 0x3E, 0xFE, 0xFA, 0x3A,
0x3B, 0xFB, 0x39, 0xF9, 0xF8, 0x38, 0x28, 0xE8, 0xE9, 0x29,
0xEB, 0x2B, 0x2A, 0xEA, 0xEE, 0x2E, 0x2F, 0xEF, 0x2D, 0xED,
0xEC, 0x2C, 0xE4, 0x24, 0x25, 0xE5, 0x27, 0xE7, 0xE6, 0x26,
0x22, 0xE2, 0xE3, 0x23, 0xE1, 0x21, 0x20, 0xE0, 0xA0, 0x60,
0x61, 0xA1, 0x63, 0xA3, 0xA2, 0x62, 0x66, 0xA6, 0xA7, 0x67,
0xA5, 0x65, 0x64, 0xA4, 0x6C, 0xAC, 0xAD, 0x6D, 0xAF, 0x6F,
0x6E, 0xAE, 0xAA, 0x6A, 0x6B, 0xAB, 0x69, 0xA9, 0xA8, 0x68,
0x78, 0xB8, 0xB9, 0x79, 0xBB, 0x7B, 0x7A, 0xBA, 0xBE, 0x7E,
0x7F, 0xBF, 0x7D, 0xBD, 0xBC, 0x7C, 0xB4, 0x74, 0x75, 0xB5,
0x77, 0xB7, 0xB6, 0x76, 0x72, 0xB2, 0xB3, 0x73, 0xB1, 0x71,
0x70, 0xB0, 0x50, 0x90, 0x91, 0x51, 0x93, 0x53, 0x52, 0x92,
0x96, 0x56, 0x57, 0x97, 0x55, 0x95, 0x94, 0x54, 0x9C, 0x5C,
0x5D, 0x9D, 0x5F, 0x9F, 0x9E, 0x5E, 0x5A, 0x9A, 0x9B, 0x5B,
0x99, 0x59, 0x58, 0x98, 0x88, 0x48, 0x49, 0x89, 0x4B, 0x8B,
0x8A, 0x4A, 0x4E, 0x8E, 0x8F, 0x4F, 0x8D, 0x4D, 0x4C, 0x8C,
0x44, 0x84, 0x85, 0x45, 0x87, 0x47, 0x46, 0x86, 0x82, 0x42,
0x43, 0x83, 0x41, 0x81, 0x80, 0x40
  ];

@Injectable({
  providedIn: 'root'
})
export class BleService {
  private ble;
  private selectedDevice = null;
  private notifyCharacteristic = null;
  private writeCharacteristic = null;

  

  private device: any;

  constructor() {
    this.ble = (navigator as any).bluetooth;
    if (!this.ble) {
      throw new Error('Your browser does not support Smart Bluetooth. See http://caniuse.com/#search=Bluetooth for more details.');
    }

    // Bind the handleNotifications method to the class instance
    this.handleNotifications = this.handleNotifications.bind(this);

  }

  async requestDevice(options: any): Promise<any> {
    //this.device = this.ble.requestDevice(options);
    
    try {
      console.log("Starting BLE scan...");
      this.device = await this.ble.requestDevice({
        acceptAllDevices: true,
        filter:[
          {services: ["6e400001-b5a3-f393-e0a9-e50e24dcca9e"]}
        ],
        optionalServices:["6e400001-b5a3-f393-e0a9-e50e24dcca9e"]
      });

      console.log("Device selected:", this.device.name || "Unknown Device");
      //document.getElementById("status").innerText = `Connecting to: ${this.device.name}`;

      this.selectedDevice = this.device;
      await this.connectToDevice(this.device);
  } catch (error) {
      console.error("BLE Scan Error:", error);
  }
  }

  async connectToDevice(device) {
    try {
        console.log("Connecting to device...");
        const server = await device.gatt.connect();
        console.log("Connected to GATT Server");

        const service = await server.getPrimaryService("6e400001-b5a3-f393-e0a9-e50e24dcca9e");
        this.notifyCharacteristic = await service.getCharacteristic("6e400003-b5a3-f393-e0a9-e50e24dcca9e");
        this.writeCharacteristic = await service.getCharacteristic("6e400002-b5a3-f393-e0a9-e50e24dcca9e");

        this.notifyCharacteristic.addEventListener("characteristicvaluechanged", this.handleNotifications);
        await this.notifyCharacteristic.startNotifications();
        console.log("Subscribed to Notifications");

        await this.writeToCharacteristic();
    } catch (error) {
        console.error("Connection Error:", error);
    }
  }


  handleNotifications(event) {

    

  const crc16 = (data) => {
    let ucCRCHi = 0xFF;
    let ucCRCLo = 0xFF;

    for (let byte of data) {
        let iIndex = ucCRCLo ^ byte;
        ucCRCLo = ucCRCHi ^ CRCHi[iIndex % CRCHi.length];
        ucCRCHi = CRCLo[iIndex % CRCLo.length];
    }

    return (ucCRCHi << 8) | ucCRCLo;
  }

    
  const decryptPacket = (packetStr) => {
    packetStr = packetStr.toUpperCase();
    let packet = [];
    
    for (let i = 0; i < packetStr.length; i += 2) {
        packet.push(packetStr.substring(i, i + 2));
    }

    if (packet.length < 6) {
        console.error("Invalid packet length, cannot decrypt:", packetStr);
        return "Invalid Packet";
    }

    let encryptedNum = parseInt(packet[1], 16); // 'num'
    let encryptedDataKey = parseInt(packet[5], 16); // 'data (key)'

    // Step 3: Compute A as (num - 0x32)
    let A = encryptedNum - 0x32;

    // Step 4-6: Perform XOR-based decryption
    let decryptedDataKey = (encryptedDataKey ^ A).toString(16).toUpperCase();

    return decryptedDataKey.padStart(2, '0');
  }

  
  const encryptBLEPacket = (key) => {
    const STX = 0xFE;
    const NUM = 0x32;
    const CMD = 0x21;
    const LEN = 0x09;
    const DATA = [0x00, 0x00, 0x00, 0x00, 0xFF, 0xFF, 0xFF, 0xFF, 0x00];

    let packet = [STX, NUM, key, CMD, LEN, ...DATA];
    let xorPacket = [STX, NUM, ...packet.slice(2).map(b => b ^ 0x00)];

    let crcValue = crc16(xorPacket);
    let crcHigh = (crcValue >> 8) & 0xFF;
    let crcLow = crcValue & 0xFF;

    let finalPacket = [...xorPacket, crcHigh, crcLow];
    return finalPacket.map(b => b.toString(16).padStart(2, '0').toUpperCase()).join(" ");
  }

  
  const writeFinalPacket = async (encryptedPacket) => {
    if (!this.writeCharacteristic) {
        console.error("No write characteristic found!");
        return;
    }

    let packetBytes = encryptedPacket.split(" ").map(byte => parseInt(byte, 16));
    let data = new Uint8Array(packetBytes);

    console.log("Writing final encrypted packet:", encryptedPacket);

    try {
        console.log("data on unlock write :", data);
        await this.writeCharacteristic.writeValue(data);
        await this.writeCharacteristic.writeValue(data);
        console.log("Final Data Written Successfully:", encryptedPacket);
        //document.getElementById("status").innerText = "Final packet sent!";
    } catch (error) {
        console.error("Final Write Error:", error);
    }
  }

    let value = event.target.value;
    let receivedData = new Uint8Array(value.buffer);
    let hexData = Array.from(receivedData, byte => byte.toString(16).padStart(2, "0")).join("");

    console.log("Received Notification:", hexData);

    let decryptedData = decryptPacket(hexData);
    console.log("Decrypted Data:", decryptedData);

    let encryptedPacket = encryptBLEPacket(parseInt(decryptedData, 16));
    console.log("Final Encrypted Packet with CRC:", encryptedPacket);

    writeFinalPacket(encryptedPacket);
  }

/**
 * Writes the final encrypted packet to the write characteristic of the BLE device.
 *
 * @param {string} encryptedPacket - A string representing the encrypted packet in hexadecimal format, 
 *                                   with bytes separated by spaces.
 * 
 * This function converts the hexadecimal packet string into a Uint8Array and writes it to the 
 * write characteristic of the BLE device. It logs the progress and errors during the process.
 * If the write characteristic is not found, it logs an error and exits the function.
 */






  async writeToCharacteristic() {
    if (!this.writeCharacteristic) {
        console.error("No write characteristic found!");
        return;
    }

    let hexString = "FE6634253C4D7B60597F01044EB4F6";
    let data = new Uint8Array(hexString.match(/.{1,2}/g).map(byte => parseInt(byte, 16)));

    console.log("Writing initial request:", hexString);
    

    try {
        console.log("data on key req write", data);
        await this.writeCharacteristic.writeValue(data);
        console.log("Data Written Successfully:", hexString);
        //document.getElementById("status").innerText = "Waiting for notification...";
    } catch (error) {
        console.error("Write Error:", error);
    }
  }

}
