import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-access-dialog',
  templateUrl: './access-dialog.component.html',
  styleUrls: ['./access-dialog.component.scss']
})
export class AccessDialogComponent implements OnInit {

  data:any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public ddata: any
  ) { }

  ngOnInit(): void {
    if (this.ddata){
      this.data = this.ddata;
      this.data.request = this.data.request.split('T')[0];
    }
  }

  onNoClick(){}

}
