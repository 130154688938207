<mat-toolbar>
    Diagnostics
    <span class="spacer"></span>
    <button mat-icon-button (click)="refresh()"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>
<table *ngIf="cdata" style="width: 100%">
    <tr><th>Metric</th><th>Val.</th><th>Last</th></tr>
    <tr><td>{{'ident'|translate}}</td><td>{{raw.ident}}</td><td>{{updated.ident*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'lock.status'|translate}}</td><td>{{raw['lock.status']|bool}}</td><td>{{updated['lock.status']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'movement.status'|translate}}</td><td>{{raw['movement.status']|bool}}</td><td>{{updated['movement.status']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'engine.ignition.status'|translate}}</td><td>{{raw['engine.ignition.status']|bool}}</td><td>{{updated['engine.ignition.status']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>

    <tr></tr>
    <tr><td>{{'scooter.battery.level'|translate}}</td><td>{{raw['scooter.battery.level']}}%</td><td>{{updated['scooter.battery.level']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>

    <tr></tr>
    <tr><td>{{'gsm.signal.level'|translate}}</td><td>{{raw['gsm.signal.level']}}%</td><td>{{updated['gsm.signal.level']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'gsm.sim.iccid'|translate}}</td><td>{{raw['gsm.sim.iccid']}}</td><td>{{updated['gsm.sim.iccid']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>

    <tr></tr>
    <tr><td>{{'position.valid'|translate}}</td><td>{{raw['position.valid']|bool}}</td><td>{{updated['position.valid']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'position.latitude'|translate}}</td><td>{{raw['position.latitude']}}</td><td>{{updated['position.latitude']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'position.longitude'|translate}}</td><td>{{raw['position.longitude']}}</td><td>{{updated['position.longitude']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'position.altitude'|translate}}</td><td>{{raw['position.altitude']}}</td><td>{{updated['position.altitude']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'position.satellites'|translate}}</td><td>{{raw['position.satellites']}}</td><td>{{updated['position.satellites']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'position.speed'|translate}}</td><td>{{raw['position.speed']}}</td><td>{{updated['position.speed']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr></tr>
    
    <tr><td>{{'payload.text'|translate}}</td><td>{{raw['payload.text']}}</td><td>{{updated['payload.text']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    
    <tr></tr>
    <tr><td>{{'event.enum'|translate}}</td><td>{{raw['event.enum']}}</td><td>{{updated['event.enum']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>
    <tr><td>{{'event.priority.enum'|translate}}</td><td>{{raw['event.priority.enum']}}</td><td>{{updated['event.priority.enum']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>

    <tr></tr>
    <tr><td>{{'error.type'|translate}}</td><td>{{raw['error.type']}}</td><td>{{updated['error.type']*1000|date:'dd/MM/yy HH:mm:ss.SS'}}</td></tr>

</table>

