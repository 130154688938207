import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'edway-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.scss']
})
export class PaymentMethodsListComponent implements OnInit {

  @Input() user: string;
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  rowData =[];
  show = false;
  constructor(
    private e: EdwayService,
    private tr: TranslateService,
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.columnDefs = [
      { headerName: this.tr.instant('user.paymentmode.type'), field: 'type', resizable: true },
      { headerName: this.tr.instant('user.paymentmode.card.brand'), field: 'card.brand', resizable: true },
      { headerName: this.tr.instant('user.paymentmode.card.country'), field: 'card.country', resizable: true },
      { headerName: this.tr.instant('user.paymentmode.card.funding'), field: 'card.funding', resizable: true },
      { headerName: this.tr.instant('user.paymentmode.card.last4'), field: 'card.last4', resizable: true },
    ];
    let ret = [];
    this.e.getPaymentMethods(this.user).subscribe((data:any[]) => {
      this.rowData = data;
      this.show = true;
    })
  }

}
