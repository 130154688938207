<form>
<h1 mat-dialog-title>{{'rentals.new.title'|translate}}</h1>
<mat-dialog-content>
        <mat-form-field class="example-full-width">
          <mat-label>{{'rentals.new.username'|translate}}</mat-label>
          <input matInput name="username" [(ngModel)]="d.username"  placeholder="{{'rentals.new.username'|translate}}">
          <button matSuffix mat-icon-button (click)="d.user=d.username"><mat-icon>search</mat-icon></button>
        </mat-form-field>
        <div *ngIf="d.user">
            <mat-form-field class="example-full-width">
                <mat-label>{{'rentals.new.vehicle'|translate}}</mat-label>
                <input matInput [disabled]="true" name="vehicle" [(ngModel)]="d.vehicle" [disabled]="true" placeholder="{{'rentals.new.vehicle'|translate}}" >
              </mat-form-field>
        </div>
        <mat-form-field class="example-full-width">
          <mat-label>{{'rentals.new.vehicle'|translate}}</mat-label>
          <input matInput name="vehicle" [(ngModel)]="d.vehicle" [disabled]="true" placeholder="{{'rentals.new.vehicle'|translate}}" >
        </mat-form-field>
    </mat-dialog-content>
    <mat-action-list>
        <button mat-button>{{'ops.cancel'|translate}}</button>
    <button mat-raised-button>{{'ops.ok'|translate}}</button>
</mat-action-list>

</form>