import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-unlock-dialog',
  templateUrl: './unlock-dialog.component.html',
  styleUrls: ['./unlock-dialog.component.scss']
})
export class UnlockDialogComponent implements OnInit {

  number: string = "";


  nums = new Set(['1','2','3','4','5','6','7','8','9','0']);
  

  constructor(
    private e: EdwayService,
    private sb: MatSnackBar,
    private tr: TranslateService
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
  }

  clear(){
    if (this.number.length>5){
      if (this.hasOnlyNumbers(this.number)){
        this.e.unlockPhone(this.number).subscribe(data=>{
          this.sb.open(this.tr.instant('op.result.phone_unlocked'), 'ok', {duration:500});
        })
      } else {
        this.sb.open(this.tr.instant('op.result.phone_not_valid'), 'ok', {duration: 1000});
      }
    } else {
      this.sb.open(this.tr.instant('op.result.phone_too_short'), 'ok', {duration: 1000});
    }
  }

  hasOnlyNumbers(str:string){
    return [...str[0]=='+'?str.substring(1):str].every(x=>this.nums.has(x))
  };


}
