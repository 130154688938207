import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  files: any[]= [];

  constructor(
    private e: EdwayService,
    private _sb: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.updateList();
  }

  delete(file){
    this.e.deleteFile(file).subscribe(data=>{
      this._sb.open('deleted','ok', {duration:500})
      this.updateList();
    })
  }
  
  updateList(){
    
    this.e.getFiles().subscribe((data:any)=>{
      this.files = data;
    })
  }

}
