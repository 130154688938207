import { DOCUMENT } from '@angular/common';
import { Component, ContentChild, Inject, OnInit, ViewChild } from '@angular/core';
import * as echarts from 'echarts';
import { EdwayService } from 'src/app/edway.service';

type EChartsOption = echarts.EChartsOption

@Component({
  selector: 'edway-stats-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent implements OnInit {

  public period = "week";
  public graph = "trip-count-by-fleet";
  chart: any;
  option:EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      data: ['Email', 'Union Ads', 'Video Ads', 'Direct', 'Search Engine']
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: [
    ]
  };
  
  @ContentChild('chart') dom: ContentChild;

  constructor(
    @Inject(DOCUMENT) document: Document,
    private e: EdwayService
  ) { }

  ngOnInit(): void {
    this.getData()
  }

  getData(){
    this.e.getStatistics('general', {x:this.period, y:this.graph}).subscribe((data:any)=>{
      this.option.xAxis[0].data = data.x;
      this.option.series = data.y;
      this.chart = echarts.init(document.getElementById('graph'));
      this.option && this.chart.setOption(this.option);
    })

  }

  setPeriod(period){
    this.period = period;
    this.getData();
  }

  setGraph(graph){
    this.graph = graph;
    this.getData()
  }

}
