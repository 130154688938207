<h1 mat-dialog-title>Add Role</h1>
<mat-dialog-content>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Username</mat-label>
    <input matInput  type="text" [(ngModel)]="data.user">
  </mat-form-field>
  <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Fleet</mat-label>
    <mat-select [(ngModel)]="data.fleet">
      <mat-option *ngFor="let f of fleets" [value]="f.id">{{f.name}}</mat-option>
    </mat-select>

  </mat-form-field>
    
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Cancel</button>
  <button mat-button (click)="addRole(data)">OK</button>
</mat-dialog-actions>
