import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-vehicle-cell-renderer',
  templateUrl: './vehicle-cell-renderer.component.html',
  styleUrls: ['./vehicle-cell-renderer.component.scss']
})
export class VehicleCellRendererComponent implements ICellRendererAngularComp  {
  private params!: ICellRendererParams;
  public style!: any;
  public value!: number;
  public imgForMood!: string;
  public charging!: boolean;

  constructor() { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    try {
      this.value = parseInt(params.data['edway.battery'].toFixed(0));
      this.charging = params.data['battery.charging.status'];
      if(this.value > 75)
        this.style = {color: 'green'};
      else if(this.value > 50)
          this.style = {color: 'orange', "font-weight": "bold"};
      else if(this.value > 25)
          this.style = {color: 'red', "font-weight": "bold"};
      else
          this.style = {color: 'gray', "font-weight": "bold"};
    } catch (ex) {
      this.style = {color: 'gray', "font-weight": "bold"};
    }
  }
}
