import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { NodeEventHandler } from 'rxjs/internal/observable/fromEvent';

@Component({
  selector: 'app-amend',
  templateUrl: './amend.component.html',
  styleUrls: ['./amend.component.scss']
})
export class AmendComponent implements ICellRendererAngularComp {
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  public params: any;

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  btnClickedHandler(button, event: Event) {
    button.clicked(this.params.data);
    event.preventDefault();
    event.stopPropagation();
    return false;
  }

  condition(button){
    if(this.params)
      if (this.params.data)
        return button.condition(this.params.data);
    return false;
  }


}
