import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {

  data = {operator: null, api_key: null, label: null};
  constructor() { }

  ngOnInit(): void {
  }

}
