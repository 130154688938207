<button mat-icon-button  [matBadge]="'!'" [matBadgeHidden]="!filterSet" [matMenuTriggerFor]="rentalFilterMenu"><mat-icon>search</mat-icon></button>


<mat-menu #rentalFilterMenu>
  <button mat-button [disabled]="!filterSet" (click)="clear()" style="width:100%">Reset</button>
  <br>
  <mat-button-toggle-group
    [(ngModel)]="filter.type"
    (ngModelChange)="update($event)"
    name="filter"
    aria-label="Ingredients"
    vertical="true"
    multiple
  >
    <mat-button-toggle class="small" value="unpaid">
      {{ "rentalstatus.unpaid" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="paid">
      {{ "rentalstatus.paid" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="nopay">
      {{ "rentalstatus.nopay" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="ongoing">
      {{ "rentalstatus.ongoing" | translate }}
    </mat-button-toggle>
    <mat-button-toggle class="small" value="start_pending">
      {{ "rentalstatus.start_pending" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
</mat-menu>