<h1 mat-dialog-title>Connection Configuration</h1>
<div mat-dialog-content>
  <p>To connect your devices to the HopOnMobility infrastructure you can use the following connection configurations.</p>
  <table>
    <tr><th></th><th>Host</th><th>Port</th><th></th></tr>
    <tr><th>Okai</th><td>okai.iot.hoponmobility.com</td><td>29008</td></tr>
    <tr><th>Omni</th><td>omni.iot.hoponmobility.com</td><td>28788</td></tr>
    <tr><th>Segway</th><td>segway.iot.hoponmobility.com</td><td>21807</td></tr>
    <tr><th>Teltonika</th><td>teltonika.iot.hoponmobility.com</td><td>27186</td></tr>
    
  </table>

</div>
<div mat-dialog-actions>
  <button mat-button [mat-dialog-close] cdkFocusInitial>Ok</button>
</div>
