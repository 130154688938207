import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../edway.service';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit {

  public d;
  constructor(
    private ed: EdwayService
  ) { }

  ngOnInit(): void {
    this.ed.getStats().subscribe(data=>{
      this.d = data;
    })
  }

}
