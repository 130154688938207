import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EdwayService } from '../../edway.service';
import { AuthService } from '../auth.service';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    private auth: AuthService,
    private e: EdwayService,
    private r: Router,
  ) {}

  ngOnInit(): void {}

  login(username, password) {
    this.auth.login(username, password).subscribe((res: any) => {
      //localStorage.setItem('access_token', res.access_token);
      this.r.navigate(['/'])
    });
  }

  recover() {
    const p = prompt("Reset password for user")
    if (p) {
      this.auth.sendNewPw(p).subscribe(data => {})
    }
  }


  botName = "edwaymgmt_bot";

  onLoad() {
    alert('loaded');
  }

  onLoadError() {
    alert('error')
  }

  onLogin(user: any) {
    console.log(JSON.stringify(user, null, 4));
    this.auth.telegram(user).subscribe((res: any) => {
      localStorage.setItem('access_token', res.access_token);
      this.e.getLoggedin(res.username).subscribe((data: any) => {
        localStorage.setItem('client-id', data.clients[0]);
        localStorage.setItem('clients', JSON.stringify(data.clients));
        this.r.navigate(['/'])
      })
    })
  }

}
