import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-logdata',
  templateUrl: './logdata.component.html',
  styleUrls: ['./logdata.component.scss']
})
export class LogdataComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<LogdataComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  keys(k){
    return Object.keys(k);
  }
  
  public isDictionary(object: unknown): object is Record<keyof never, unknown> {
    return object instanceof Object && object.constructor === Object
  }
}
