import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';
import { Papa } from 'ngx-papaparse';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {

  data: any = {op: "Add"};
  models: any[];
  fleets: any[];

  mode = "single";

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<EditComponent>,
    private papa: Papa,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) { }

  ngOnInit(): void {
    this.e.getModels().subscribe((data: any[]) =>{
      this.models = data;
    })
    this.e.getRealFleets().subscribe((data: any[]) =>{
      this.fleets = data;
    })
    console.log(this.idata);
    if(this.idata){
      this.data = this.idata;
    }

  }
  
  onPaste(event: ClipboardEvent) {
    this.mode = "paste";
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    this.papa.parse(pastedText,{
      header: true,
      complete: (result) => {
          console.log('Parsed: ', result);
          if (result.data.length > 1){
            for(let row of result.data){
              if (row.IMEI){
                this.data.vehicles.push({imei:row.IMEI, model: this.data.vehicles[0].model, fleet: this.data.vehicles[0].fleet, extra: row})
              }
            }
            this.data.vehicles.shift();
          }
      }
  });

  }
  
  
  onNoClick(): void {
    this.dialogRef.close();
  }

  addRow(){
    this.data.vehicles.push({imei:null, model:null, fleet:null})
  }

  addVehicles(vehicles){
    this.e.addVehicles(vehicles).subscribe(data=>{
      console.log('done');
    });
  }
}
