import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add-package',
  templateUrl: './add-package.component.html',
  styleUrls: ['./add-package.component.scss']
})
export class AddPackageComponent implements OnInit {

  packages: any = [];
  data: any = {op: "Add"};
  models: any[];

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddPackageComponent>,
    @Inject(MAT_DIALOG_DATA) public idata: any
  ) { }

  ngOnInit(): void {
    this.e.getProducts().subscribe(data=>{
      this.packages = data;
    })
    console.log(this.idata);
    if(this.idata){
      this.data = this.idata;
    }
  }

  
  onNoClick(): void {
    this.dialogRef.close();
  }

}
