import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridReadyEvent,
  ICellRendererParams,
  SelectionChangedEvent,
  ValueFormatterParams,
} from 'ag-grid-community';
import { EdwayService } from '../../edway.service';
import { saveAs } from 'file-saver';
import { EdwayDatasource } from 'src/app/edway-datasource';
import { TranslateService } from '@ngx-translate/core';
import { RentalStatusRendererComponent } from 'src/app/rental-status-renderer/rental-status-renderer.component';
import { AmendComponent } from '../amend/amend.component';
import { MatDialog } from '@angular/material/dialog';
import { AmendDialogComponent } from '../amend-dialog/amend-dialog.component';
import { AddComponent } from 'src/app/payments/add/add.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DlfilterComponent } from 'src/app/dlfilter/dlfilter.component';
import { DownloadService } from 'src/app/download.service';
import { DatePipe } from '@angular/common';
import { DateUtilService } from 'src/app/services/date-util.service';

@Component({
  selector: 'edway-rentals-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [EdwayDatasource],
})
export class ListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  pageEvent: PageEvent;

  @Input() fleet: string;
  @Input() vehicle: string;
  @Input() user: string;

  private gridApi!: GridApi;

  multiSelected = false;
  multiSelect = [];

  filter: any = {};

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private ds: EdwayDatasource,
    private tr: TranslateService,
    private d: MatDialog,
    private sb: MatSnackBar,
    private downloadService: DownloadService,
    private dateUtil: DateUtilService
  ) {}
  // MatPaginator Output

  async ngOnInit() {
    this.filter['status'] = this.ar.snapshot.paramMap.get('filter.status');
    await this.tr.get('dummyTranslation').toPromise().then();
    let ret = [];

    this.columnDefs = [
      {
        field: 'status',
        //filter: false,
        maxWidth: 60,
        headerName: '',
        cellRenderer: RentalStatusRendererComponent,
      },
      {
        field: 'actions',
        filter: false,
        maxWidth: 170,
        minWidth:170,
        checkboxSelection: true,
        headerName: this.tr.instant('fields.rentals.actions'),
        cellRenderer: AmendComponent,
        cellRendererParams: {
          buttons: [
            {
              label: this.tr.instant('op.amend'),
              icon: 'edit',
              condition: (params) => {
                if (params) {
                  if (params.status !== 'cancel'){
                    if (params.penalties)
                      return (
                        params.penalties.filter((x) => x.stripe_confirmed)
                          .length > 0 && params.amount > 0
                      );
                    else return false;
                  } else return false;
                }
              },
              clicked: (params: ICellRendererParams) => {
                this.d
                  .open(AmendDialogComponent, { data: params })
                  .afterClosed()
                  .subscribe((data) => {
                    ////console.log(data);
                    this.update();
                  });
                return false;
              },
            },
            {
              label: 'op.repeat',
              icon: 'refresh',
              condition: (params) => {
                  if (params.status !== 'cancel'){
                    if (params.penalties)
                  return (
                    params.penalties.filter((x) => x.stripe_confirmed).length ==
                      0 && params.amount > 0
                  );
                else return false;
              } else return false;
            },
              clicked: (params: ICellRendererParams) => {
                if (
                  confirm(this.tr.instant('rentals.retry'))
                ) {
                  this.e.retryPayment(params).subscribe((data:any)=>{
                    if(data.response === 'error'){
                      this.sb.open(data.reason, "OK");
                    }
                  });
                }
                return false;
              },
            },
            {
              label: 'op.add',
              icon: 'add',
              condition: (params) => {
                  if (params.status !== 'cancel'){
                    if (params.penalties)
                  return (
                    params.penalties.filter((x) => x.stripe_confirmed).length >=
                    0
                  );
                else return false;
              } else return false;
            },
              clicked: (params: ICellRendererParams) => {
                //console.log(params);
                this.d
                  .open(AddComponent, { data: {trip: params} })
                  .afterClosed()
                  .subscribe((data) => {
                    this.update();
                  });
                return false;
              },
            },
            {
              label: 'op.stop',
              icon: 'stop',
              condition: (params) => {
                  if (params.status !== 'cancel'){
                    if (params.end) return false;
                else return true;
              } else return false;
            },
              clicked: (params: ICellRendererParams) => {
                //console.log(params);
                if (confirm('stop rental?')) {
                  this.e.closeRental(params).subscribe((data) => {
                    this.sb.open('rental closed', 'ok', { duration: 500 });
                    this.update();
                  });
                }
                return false;
              },
            },
            {
              label: 'op.cancel',
              icon: 'cancel',
              condition: (params) => {
                return params.status !== 'cancel';
              },
              clicked: (params: ICellRendererParams) => {
                //console.log(params);
                if (confirm(this.tr.instant('question.rentals.cancel'))) {
                  this.e.cancelRental(params).subscribe((data) => {
                    this.sb.open('rental cancelled', 'ok', { duration: 500 });
                    this.update();
                  });
                }
                return false;
              },
            },
          ],
        },
      },
      { field: 'user', headerName: this.tr.instant('fields.rentals.user') },
      {
        field: 'vname',
        maxWidth: 100,
        headerName: this.tr.instant('fields.rentals.vname'),
      },
      {
        field: 'amount',
        valueFormatter: currencyFormatter,
        headerName: this.tr.instant('fields.rentals.payment.amount'),
      },
      {
        field: 'duration',
        valueFormatter: (params) => {
          if (params.value) {
            ////console.log(params.value);
            const v = params.value;
            const s = v % 60;
            const m = Math.floor(v / 60) % 60;
            const h = Math.floor(v / 3600) % 24;
            const d = Math.floor(v / (24*60*60));
            return '' + d + this.tr.instant('time.days') + ' ' + h + this.tr.instant('time.hours') +' ' + m + this.tr.instant('time.minutes') +' ' + s.toFixed(0) + this.tr.instant('time.seconds');
          } else {
            return '';
          }
        },
        headerName: this.tr.instant('fields.rentals.duration'),
      },
      {
        field: 'start',
        filter: 'agDateColumnFilter',
        headerName: this.tr.instant('fields.rentals.start'),
        valueFormatter: (v) => this.dateUtil.dateTableFormatter(v)
      },
      {
        field: 'end',
        filter: 'agDateColumnFilter',
        headerName: this.tr.instant('fields.rentals.end'),
        valueFormatter: (v) => this.dateUtil.dateTableFormatter(v)
      },
    ];

    this.ds.setUrl(this.e.endpoints.rentals);
    this.ds.inform(this.e);
    const fltr = {};
    if (this.vehicle) {
      fltr['vehicle'] = this.vehicle;
    }
    if (this.user) {
      fltr['user'] = this.user;
    }
    this.ds.setFilter(fltr);
    this.ds.setPostOperartions((data) => {
      //console.log(data);
      data.map((x: any) => {
        const start = new Date(x['start']);
        let end = new Date();
        if(x['end']){
          end = new Date(x['end']);
          x['end'] = end;
        } 
        const duration: any = end.getTime() / 1000 - start.getTime() / 1000;
        x['closed'] = x['end'] != null;
        x['duration'] = duration;
        try{
          x['vname'] = x['name'].filter((y) => y.context === 'internal')[0].code;
        } catch (ex){
          x['vname'] = x['imei'];
        }
        return x;
      });
      return data;
    });
    this.update();
  }

  onRowClicked(ev) {
    this.r.navigate(['rentals', ev.data.id]);
  }

  onSelectionChanged(event: SelectionChangedEvent) {
    this.multiSelect = this.gridApi.getSelectedRows();
    this.multiSelected = this.multiSelect.length>1;
  }

  massRetry(event){
    if(confirm('Retry payments?')){
      this.multiSelect = [];
      this.multiSelected = false;
      this.gridApi.deselectAll();
    }
  }

  update(triggered = false) {
    this.show = false;
    if (triggered) {
      try{
        this.gridApi.purgeInfiniteCache();
      } catch(ex){

      }
    }
    const params = this.filter;
    if (this.fleet) {
      params['fleet'] = this.fleet;
    }
    if (this.vehicle) {
      params['vehicle'] = this.vehicle;
    }
    if (this.user) {
      params['user'] = this.user;
    }
    this.e.getRentals(params).subscribe((data: any[]) => {
      //console.log(data);
      this.list = data.map((x) => {
        x.name = {};
        if (x.codes) {
          for (let c of x.codes) {
            x.name[c.context] = c.code;
          }
        }
        return x;
      });
      this.show = true;
    });
  }

  toPage(page) {
    this.update(page.pageIndex + 1);
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    params.api!.setDatasource(this.ds);
    if (this.vehicle) {
      this.ds.setRaw({vehicle:this.vehicle});
    }
    if (this.user){
      this.ds.setRaw({user:this.user});
    }
  }

  download(url) {
    this.downloadService
      .download(
        url,
        {
          headers: {
            Accept:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
        'rentals'
      )
      .subscribe(() => {});
  }

  
  apply(filter) {
    //console.log(filter);
    if (filter.type){
      const f = this.gridApi.getFilterInstance('status');
      f.setModel({
        filterType: 'text',
        type: 'contains',
        filter: filter.type.join(' OR '),
      });
    }
    this.gridApi.onFilterChanged();
  }
}
function currencyFormatter(params: ValueFormatterParams) {
  if (params.value) {
    return (
      params.data.payment.currency.replace('eur', '€ ') +
      params.value.toFixed(2)
    );
  } else {
    return null;
  }
}
