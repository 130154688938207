<h1 mat-dialog-title translate>op.reimburse.title</h1>
<mat-dialog-content>
    <mat-form-field appearance="fill">
        <mat-label translate>op.reimburse.amount</mat-label>
        <input type="number" matNativeControl required [(ngModel)]="data.amount">
      </mat-form-field>
      <br>
      <mat-form-field appearance="fill">
          <mat-label translate>op.reimburse.reason</mat-label>
          <input matNativeControl required [(ngModel)]="data.reason">
        </mat-form-field>
</mat-dialog-content>
<mat-action-list>
    <button mat-button>Cancel</button>
    <button mat-raised-button (click)="reimburse(data)">OK</button>
</mat-action-list>
