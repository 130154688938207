import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'vehicle'
})
export class VehiclePipe implements PipeTransform {

  transform(vid: string): string {
    return vid;
  }

}
