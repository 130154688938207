import { AfterContentChecked, AfterViewChecked, Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';
import { EdwayService } from 'src/app/edway.service';
import { SettingsService } from 'src/app/settings.service';
import { AddFleetSettingComponent } from '../add-fleet-setting/add-fleet-setting.component';

declare const mapboxgl: any;
declare const L: any;

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss']
})
export class DetailComponent implements OnInit {

  fleet_id: string;
  fleet: any;
  selected = new FormControl(0);
  areas: any;
  mode: string = "fleets";
  constructor(
    private e: EdwayService,
    private ar: ActivatedRoute,
    private a: AuthService,
    private s: SettingsService,
    private r: Router,
    public auth: AuthService,
    private tr: TranslateService,
    private d: MatDialog,
    private sb: MatSnackBar,
  ) { }

  ngOnInit(): void {
    this.mode = this.ar.snapshot.paramMap.get('mode');
    console.log(this.mode);
    this.ar.paramMap.subscribe(params => {
      this.fleet_id = params.get('fleetId');
      this.mode = params.get('mode');
      this.update();
    })
  }

  showmap(): void {
    
  }

  deleteFleetSetting(fleet, key){
    if(confirm('delete setting for fleet area?')){
      this.e.deleteFleetSetting(fleet, key).subscribe(data=>{
        this.update();
      })
    }
  }
  

  update(){
    this.e.getFleets({id:this.fleet_id}).subscribe((data: any)=>{
      this.fleet = data;
      this.fleet.list_payments_accepted = this.fleet.payments_accepted.split("|")
      this.areas = {
        type: 'FeatureCollection',
        features : data.areas.map(x=>{
          const poly = x.polygon
          poly.properties = x
          return poly;
        })
      }
      console.log(this.areas);
    })
  }

  deleteFleet(id){
    if(confirm(this.tr.instant('confirm.delete'))){
      this.e.deleteFleet(id).subscribe(data=>{
        this.sb.open(this.tr.instant('snackbar.success'), 'ok', {duration:500});
      })
    }
  }

  deactivateFleet(id){}

  managers(id){
    this.r.navigate(['/settings/roles', {"fleet": id}]);
  }
  is_manager(fleet){
    return false;
  }

  canDo(op, fleet){
    return this.a.canDo(op, fleet);
  }

  toggleSetting(event, id, setting){
    this.e.toggleFleetSetting(id, setting, event.checked).subscribe(data=>{
      this.update();
    })
  }

  editFleet(id){

  }

  addManager(id){
    const uname = prompt(this.tr.instant('fleets.details.add_manager'));
    if (uname){
      this.e.addManager({username: uname, fleet: id}).subscribe(data=>{
        console.log(data);
        this.sb.open(this.tr.instant('fleets.details.confirm'), null, {duration: 1000});
      })
    }
  }

  toggleChannel(event, id, ch){
    
  }

  openAddFleetSetting(){
    this.d.open(AddFleetSettingComponent, {data: {fleet: this.fleet.id, pagemode: this.mode}})
  }
}
