<mat-toolbar>
    <span translate>translate.title</span>
    <span class="spacer"></span>
    
</mat-toolbar>

<mat-drawer-container class="example-container" autosize>
    <mat-drawer #drawer opened="true" class="example-sidenav" mode="side">
        <mat-nav-list>
            <a mat-list-item [routerLink]="['/', 'translations','mobile']">{{'translate.modes.mobile'|translate}}</a>
            <a mat-list-item [routerLink]="['/', 'translations','dashboard']">{{'translate.modes.dashboard'|translate}}</a>
        </mat-nav-list>
    </mat-drawer>
  
    <div class="example-sidenav-content">
        <router-outlet></router-outlet>
    </div>  
</mat-drawer-container>