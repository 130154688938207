import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-models-detail',
  templateUrl: './models-detail.component.html',
  styleUrls: ['./models-detail.component.scss']
})
export class ModelsDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
