<h1 mat-dialog-title>Add to Blacklist</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Reason</mat-label>
      <select matNativeControl required [(ngModel)]="data.reason">
        <option value="outstanding_payment">Outstanding Payment</option>
        <option value="toxic_behaviour">Vandalism</option>
      </select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
