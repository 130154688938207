<mat-toolbar>
    Files
    <button mat-icon-button (click)="updateList()"><mat-icon>refresh</mat-icon></button>
</mat-toolbar>

<mat-list>
    <mat-list-item *ngFor="let f of files">
        
        <span class="ft">{{f.att_type}}</span>
        <a [href]="f.url" class="dl" mat-icon-button><mat-icon>download</mat-icon></a>
        <span class="fn">{{f.name}}</span>
        <span class="spacer"></span>
        <button mat-icon-button (click)="delete(f.id)"><mat-icon>delete</mat-icon></button>
    </mat-list-item>
</mat-list>