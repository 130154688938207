<mat-toolbar>
    <span translate>dashboard.docs</span>
    <button mat-icon-button (click)="refresh()"><mat-icon>refresh</mat-icon></button>
    <span class="spacer"></span>
    <mat-button-toggle-group>

    </mat-button-toggle-group>
    <button mat-button [ngClass]="{'active': status==='todo'}" (click)="toggleFilter('todo')">{{'docs.todo'|translate}}</button>
    <button mat-button [ngClass]="{'active': status==='accepted'}" (click)="toggleFilter('accepted')">{{'docs.accepted'|translate}}</button>
    <button mat-button [ngClass]="{'active': status==='refused'}" (click)="toggleFilter('refused')">{{'docs.refused'|translate}}</button>
    <button mat-icon-button (click)="search()"><mat-icon>search</mat-icon></button>

    <span class="spacer"></span>
</mat-toolbar>

<table>
    <tr>
        <th>{{'docs.col.upload'|translate}}</th>
        <th>{{'docs.col.user'|translate}}</th>
        <th>{{'docs.col.document'|translate}}</th>
        <th>{{'docs.col.status'|translate}}</th>
        <th>{{'docs.col.front'|translate}}</th>
        <th>{{'docs.col.back'|translate}}</th>
        <th>{{'docs.col.selfie'|translate}}</th>
        <th>{{'docs.col.actions'|translate}}</th>
    </tr>
    <tr *ngFor="let doc of list">
        <td>{{doc.created|date}}</td>
        <td>{{doc.user}}</td>
        <td>{{doc.doc_type}}</td>
        <td>{{doc.valid}}</td>
        <td><img width="150" (click)="open(doc.url)" src="{{doc.url}}" ></td>
        <td><img width="150" (click)="open(doc.url_back)" src="{{doc.url_back}}" ></td>
        <td><img width="150" (click)="open(doc.url_selfie)" src="{{doc.url_selfie}}" ></td>
        <td>
            <button
            mat-raised-button
            class="accept"
            (click)="accept(doc.user, doc.doc_type)"
          >
            <i class="fa-fw fa-solid fa-check"></i>Accetta</button
          >
          <button
            mat-raised-button
            class="refuse"
            (click)="refuse(doc.user, doc.doc_type)"
          >
            <i class="fa-fw fa-solid fa-x"></i>Rifiuta
          </button>
        </td>
    </tr>
</table>