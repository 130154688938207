import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add-fleet-setting',
  templateUrl: './add-fleet-setting.component.html',
  styleUrls: ['./add-fleet-setting.component.scss']
})
export class AddFleetSettingComponent implements OnInit {

  data = {
    key: "",
    value: ""
  }

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddFleetSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public adata: {fleet:string, pagemode:string}
  ) { }

  ngOnInit(): void {
  }

  save(){
    this.e.addFleetSetting(this.adata.fleet, this.data.key, this.data.value).subscribe((data)=>{
      this.dialogRef.close({
        fleet: this.adata.fleet,
        key: this.data.key,
        value: this.data.value
      });
    })
  }

}
