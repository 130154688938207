import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../edway.service';
import { MatDialog } from '@angular/material/dialog';
import { AddComponent } from './add/add.component';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-beacons',
  templateUrl: './beacons.component.html',
  styleUrls: ['./beacons.component.scss']
})
export class BeaconsComponent implements OnInit {

  show = false;
  constructor(
    private e: EdwayService,
    private d: MatDialog,
    private t: Title,
  ) { }

  ngOnInit(): void {
    this.t.setTitle('HopOnMobility - Beacons')

  }


  addBeacon(){
    this.d.open(AddComponent);
  }
}
