<h1 mat-dialog-title>Aggiunta Accessi</h1>
<div mat-dialog-content>
  <p>Dati utente da aggiungere agli accessi</p>
  <div class="column">
    <mat-form-field appearance="fill">
      <mat-label>Email</mat-label>
      <input matInput [(ngModel)]="data.user">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Data di Inizio accesso</mat-label>
      <input type="date" matInput [(ngModel)]="data.request">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Data di fine accesso</mat-label>
      <input type="date" matInput [(ngModel)]="data.end">
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">No Thanks</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>