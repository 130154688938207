import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

  status = "pre";
  token = null;


  constructor(
    private auth: AuthService,
    private r: Router,
    private e: EdwayService,
  ) { }

  ngOnInit(): void {
  }

  start_reset(username){
    this.auth.getOTP(username).subscribe((data:any)=>{
      this.status = "post";
      this.token = data.token;
    })
  }

  pw_reset(username, otp, password){
    this.auth.otpLogin(username, otp, password, this.token).subscribe((data:any) => {
      this.status = "checked";      
      this.e.getLoggedin(username).subscribe((data:any) => {
        localStorage.setItem('client-id', data.clients[0]);
        localStorage.setItem('clients', JSON.stringify(data.clients));
        this.r.navigate(['/'])
      })
    })
  }

}
