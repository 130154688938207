<mat-toolbar>
    <h1> <span translate>dashboard.profile</span></h1>
    <span class="spacer"></span>
    <button mat-icon-button [disabled]="true"><mat-icon>edit</mat-icon></button>
</mat-toolbar>

<mat-tab-group>
    <mat-tab label="Connections">
        <table>
            <tr><th>Telegram</th><td>
                <angular-telegram-login-widget 
                    (login)="onTelegramLogin($event)"
                    [botName]="telegramBotName">
                </angular-telegram-login-widget>
            </td></tr>
        </table>
    </mat-tab>
    <mat-tab label="DEV INFO">
        {{profile|json}}
    </mat-tab>
</mat-tab-group>