<mat-toolbar>
    <h3>{{ "users.details.payment_modes" | translate }}</h3>
</mat-toolbar>

<mat-tab-group>
    <mat-tab label="Stripe">
        <ag-grid-angular  *ngIf="show"
            class="fullpage ag-theme-alpine"
            [rowData]="rowData"
            [headerHeight]="36"
            [defaultColDef]="defaultColDef"
            [columnDefs]="columnDefs"
            >
        </ag-grid-angular>
    </mat-tab>
    <mat-tab label="Checkout" [disabled]="true">
        
    </mat-tab>
</mat-tab-group>