import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { EdwayDatasource } from 'src/app/edway-datasource';
import { EdwayService } from 'src/app/edway.service';
import { UnlockDialogComponent } from '../unlock-dialog/unlock-dialog.component';

@Component({
  selector: 'app-botp',
  templateUrl: './botp.component.html',
  styleUrls: ['./botp.component.scss'],
  providers: [EdwayDatasource]
})
export class BotpComponent implements OnInit {

  public defaultColDef: ColDef = {
    editable: false,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  rowData =[];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private ds: EdwayDatasource,
    private d: MatDialog,
    private tr: TranslateService
  ) { }


  ngOnInit(): void {
    this.e.getBlocked().subscribe(data=>{
      this.show = true;

    })
  }

  unlockPhone(){
    this.d.open(UnlockDialogComponent);
  }

}
