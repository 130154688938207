import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'edway-vehicle-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit {

  
  raw = {};
  updated = {};

  cdata = {};

  @Input() set data(value: any){
    this.cdata = value;
    this.raw = Object.assign(this.raw, this.cdata);
    for(let k in this.cdata){
      this.updated[k]= this.cdata['timestamp'];
    }
  }


  constructor() { }

  ngOnInit(): void {
  }

  refresh(){

  }

}
