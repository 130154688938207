import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { ActionsCellRendererComponent } from 'src/app/coupons/list/actions-cell-renderer.component';
import { EdwayService } from 'src/app/edway.service';
import { ImageboxComponent } from 'src/app/imagebox/imagebox.component';
import { AmendDialogComponent } from 'src/app/rentals/amend-dialog/amend-dialog.component';
import { AmendComponent } from 'src/app/rentals/amend/amend.component';
import { VehicleCellRendererComponent } from 'src/app/vehicle-cell-renderer/vehicle-cell-renderer.component';

@Component({
  selector: 'app-models-list',
  templateUrl: './models-list.component.html',
  styleUrls: ['./models-list.component.scss']
})
export class ModelsListComponent implements OnInit {
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [
  ];
  rowData: any[];

  openFile(url: string){
    console.log('hell');
    let doc = (<HTMLInputElement>document.querySelector('input#files'));   
    doc.setAttribute('data-url', url);
    doc.click();
  }

  handle(e){
    console.log('Change input file', e);
    this.e.uploadFile(e.target.files[0], e.target.dataset['url']).subscribe(data=>{
      console.log(data);
    });
    let doc = (<HTMLInputElement>document.querySelector('input#files'));   
    doc.setAttribute('data-url', "");
  }

  @Input() fleet: string;
  int: any;

  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private tr: TranslateService,
    private d: MatDialog
  ) { }

  documentFormatter(param){
    if(param.value){
      const pret = param.value.split('|');
      let ret = [];
      for (let r of pret){
        ret.push(this.tr.instant('docs.'+r));
      }
      return ret.join(', ');
    } else {
      return '';
    }
  }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.columnDefs = [
    
      { field: 'model_name', headerName: this.tr.instant('models.cols.model_name') },
      { field: 'model_brand', headerName: this.tr.instant('models.cols.model_brand') },
      { field: 'vehicle_type', headerName: this.tr.instant('models.cols.vehicle_type') },
      { field: 'requires_doc', headerName: this.tr.instant('models.cols.requires_doc'), valueFormatter: (param)=> this.documentFormatter(param) },
      //{ field: 'protocol', headerName: this.tr.instant('models.cols.protocol')},
      { field: 'quantity', headerName: this.tr.instant('models.cols.quantity')},
      { field: 'image', filter:false, maxWidth: 150, headerName: this.tr.instant('models.cols.image'), cellRenderer: AmendComponent, cellRendererParams: {
        buttons:[{
          label: "op.show",
          icon: "preview",
          condition: (params) => {
            return true;
          },
          clicked: (params: any) => {
            console.log(params);
            let cli = localStorage.getItem('client-id');
            this.d.open(ImageboxComponent, {data: {img: "https://files.hoponmobility.com/edway/"+cli+"/"+params.vehicle_type+"/"+params.ident+".png"}})
            return false;
          }
        }, {
          label: "op.edit",
          icon: "edit",
          condition: (params) =>{
            return true;
          },
          clicked: (params: any) => {
            let cli = localStorage.getItem('client-id');
            this.openFile(params.vehicle_type+"/"+params.ident+".png");
            return false;
          }
        }]
      }}, 
      { field: 'marker', filter:false, maxWidth: 150, headerName: this.tr.instant('models.cols.marker'), cellRenderer: AmendComponent, cellRendererParams: {
        buttons:[{
          label: "op.show",
          icon: "preview",
          condition: (params) => {
            return true;
          },
          clicked: (params: any) => {
            console.log(params);
            let cli = localStorage.getItem('client-id');
            this.d.open(ImageboxComponent, {data: {img: "https://files.hoponmobility.com/edway/"+cli+"/"+params.vehicle_type+".png"}})
            return false;
          }
        }, {
          label: "op.edit",
          icon: "edit",
          condition: (params) =>{
            return true;
          },
          clicked: (params: any) => {
            let cli = localStorage.getItem('client-id');
            this.openFile(params.vehicle_type+".png");
            return false;
          }
        }]
      }}
    ];

    let ret = [];
    this.update();
    this.int = setInterval(()=>{this.update();}, 5000);
  }

  onRowClicked(ev){
    this.r.navigate(['/models/', ev.data.id])
  }

  update(){
    const params = {}
    if (this.fleet){
      params['fleet'] = this.fleet;
    }
    this.e.getModels(params).subscribe((data: any[]) => {
      this.rowData = data.filter(x=>x.quantity>0);
      this.show = true;
    })
  }

  ngOnDestroy(): void {
    clearInterval(this.int);
  }

}