
<h1 mat-dialog-title>{{'op.help.label'|translate}}</h1>
<mat-dialog-content>
    <mat-tab-group>
        <mat-tab label="{{'op.help.context.label'|translate}}">
            <mat-list>
                <a mat-list-item href="{{h.url}}" target="_blank" *ngFor="let h of helps">{{h.title}}</a>
            </mat-list>
        </mat-tab>
        <mat-tab label="{{'op.help.tickets.label'|translate}}">
            <mat-accordion>
                <mat-expansion-panel hideToggle *ngFor="let h of tickets" (opened)="getTicket(h)">
                  <mat-expansion-panel-header >
                    <mat-panel-title>
                      <span>#{{h.ticketNumber}} ({{h.status}})</span>
                    </mat-panel-title>
                    <mat-panel-description>
                      {{h.subject}}
                    </mat-panel-description>
                  </mat-expansion-panel-header>
                  <div [innerHTML]="openTicket.description"></div>
                  <hr>
                  <mat-icon>emoji_objects</mat-icon>{{openTicket.resolution}}
                </mat-expansion-panel>
              </mat-accordion>
        </mat-tab>
        <mat-tab label="{{'op.help.ticket_new.label'|translate}}">
          <form class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>Topic</mat-label>
              <mat-select [(ngModel)]="macroticket.topic" [ngModelOptions]="{standalone: true}">
                <mat-option value="Veicoli">Veicoli</mat-option>
                <mat-option value="Flotte">Flotte</mat-option>
                <mat-option value="Utenti">Utenti</mat-option>
                <mat-option value="Noleggio">Noleggio</mat-option>
                <mat-option value="Pacchetti">Pacchetti</mat-option>
                <mat-option value="Transazioni">Transazioni</mat-option>
                <mat-option value="Configurazione">Configurazione</mat-option>
              </mat-select>

            </mat-form-field>   
            <mat-form-field class="example-full-width">
              <mat-label>Subject</mat-label>
              <input matInput [(ngModel)]="macroticket.subject" [ngModelOptions]="{standalone: true}">
            </mat-form-field>   
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput [(ngModel)]="macroticket.description" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>  
            <edway-file-upload></edway-file-upload>
            <br>
            <button mat-raised-button color="accent" (click)="submit()">Submit</button>       
            <button mat-raised-button (click)="clear()">Clear</button>       
          </form>
        </mat-tab>
        <mat-tab label="{{'op.help.task_new.label'|translate}}">
          <form class="example-form">
            <mat-form-field class="example-full-width">
              <mat-label>Topic</mat-label>
              <mat-select [(ngModel)]="macroticket.topic" [ngModelOptions]="{standalone: true}">
                <mat-option value="Generale">Generale</mat-option>
                <mat-option value="Files">Files</mat-option>
                <mat-option value="Veicoli">Veicoli</mat-option>
                <mat-option value="Flotte">Flotte</mat-option>
                <mat-option value="Utenti">Utenti</mat-option>
                <mat-option value="Noleggio">Noleggio</mat-option>
                <mat-option value="Pacchetti">Pacchetti</mat-option>
                <mat-option value="Transazioni">Transazioni</mat-option>
                <mat-option value="Configurazione">Configurazione</mat-option>
              </mat-select>

            </mat-form-field>   
            <mat-form-field class="example-full-width">
              <mat-label *ngIf="macroticket.topic === 'Generale'">Subject</mat-label>
              <mat-label *ngIf="macroticket.topic === 'Veicoli'">Vehicles</mat-label>
              <mat-label *ngIf="macroticket.topic === 'Flotte'">Fleets</mat-label>
              <input matInput [(ngModel)]="macroticket.subject" [ngModelOptions]="{standalone: true}">
            </mat-form-field>   
            <mat-form-field class="example-full-width">
              <mat-label>Description</mat-label>
              <textarea matInput [(ngModel)]="macroticket.description" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>  
            <edway-file-upload></edway-file-upload>
            <br>
            <button mat-raised-button color="accent" (click)="submit()">Submit</button>       
            <button mat-raised-button (click)="clear()">Clear</button>       
          </form>
        </mat-tab>
    </mat-tab-group>

</mat-dialog-content>

