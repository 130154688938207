import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { EdwayService } from 'src/app/edway.service';
import { CodeEditorComponent } from '../code-editor/code-editor.component';
import { EditComponent } from '../edit/edit.component';
import { FleetEditorComponent } from '../fleet-editor/fleet-editor.component';
import { QrcodeComponent } from '../qrcode/qrcode.component';
import * as echarts from 'echarts';
import { RtdataService } from 'src/app/rtdata.service';
import { BleService } from 'src/app/ble.service';
import { NewRentalComponent } from 'src/app/rentals/new-rental/new-rental.component';

import { crc8, crc16, crc32 } from 'easy-crc';

//import { Buffer } from 'buffer';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  id:string;
  current: any;
  static: any;
  commands: any;
  fleets: any;

  vcode: string;

  updating = false;

  rtdata: any;

  mode = null;

  hasBLE = false;

  chw: any = null;
  key: any = null;
  ble_const = 0x34;

  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    public dialog: MatDialog,
    public s: MatSnackBar,
    private rt: RtdataService,
    private ble: BleService,

  ) { }

  ngOnInit(): void {
    this.id = this.ar.snapshot.params.id;
    this.mode = this.ar.snapshot.data.mode;
    this.updateVehicleCurrent();
    this.updateVehicleStatic();
    this.updateFleets();
    this.updateAvailableCommands();
    setInterval(()=> {
      this.updateVehicleCurrent();
      this.updateAvailableCommands();
    }, 5000)
  }

  updateVehicleCurrent(){
    this.e.getVehicleCurrent(this.id).subscribe(data=>{
      this.current = data;
    });
  }

  private bt;
  connectBLE(){
    this.ble.requestDevice({});
  }

  sendRawCommand(op){
  // if(this.chw){
  //   let cout = new Uint16Array(15);
  //   let utf16Encode = new TextEncoder();
  //   const name = Array.from(utf16Encode.encode("TestMesg"));
  //   daat = daat.concat(name);
  //   console.log(daat);
  //   daat = this.encryptStringWithXORtoHex(daat, 0x32, 0x34);
  //   console.log(daat);
  //   cout.set(daat, 1);
  //   console.log(cout.slice(0.13), Buffer.from(cout.slice(0,13)));
  //   const crc = this.longToByteArray(this.localCrc16(Buffer.from(cout.slice(0,13))));
  //   cout.set(crc, 13);
  //   console.log(cout);
  //   const ret = await this.chw.writeValueWithResponse(cout);
  //   console.log(ret);
  //   const msg = this.decryptSrtingWithXORtoHex(ret, 0x32);
  //   this.key = msg[5];
  // }
  }

  encryptStringWithXORtoHex(input,key_add, key_xor) {
    var added = [];
    for(var i=0; i<input.length; i++) {
      if (i == 0)
        added.push(input[i]+ key_add);
      else
        added.push(input[i])
    }
    console.log(added);
    var enced = [];
    for(var i=0; i<added.length; i++) {
      if (i == 0)
        enced.push(added[i]);
      else 
        enced.push(added[i]^key_xor);
    }

    return enced;
  }

  decryptSrtingWithXORtoHex(input, key_add){
    var ret = [];
    var num = input[1]-key_add;
    for (var i=0; i<input.length; i++){
      if (i<2)
        ret.push(input[i]);
      else
        ret.push(input[i]^num);
    }
    return ret;


  }


  longToByteArray (long) {
    // we want to represent the input as a 8-bytes array
    var byteArray = [0, 0];

    for ( var index = 0; index < byteArray.length; index ++ ) {
        var byte = long & 0xff;
        byteArray [ index ] = byte;
        long = (long - byte) / 256 ;
    }

    return byteArray.reverse();
  };

  /**
 * Calculates the buffers CRC16.
 *
 * @param {Buffer} buffer the data buffer.
 * @return {number} the calculated CRC16.
 * 
 * Source: github.com/yaacov/node-modbus-serial
 */
localCrc16(buffer) {
  var crc = 0xFFFF;
  var odd;

  for (var i = 0; i < buffer.length; i++) {
      crc = crc ^ buffer[i];

      for (var j = 0; j < 8; j++) {
          odd = crc & 0x0001;
          crc = crc >> 1;
          if (odd) {
              crc = crc ^ 0xA001;
          }
      }
  }

  return crc;
};


  async sendx11Command(rnd){
    if(this.chw){
      let cout = new Uint16Array(15);
      const cmd = [0xfe];
      cout.set(cmd,0);
      let daat = [rnd, 0x00, 0x11, 0x08];
      let utf16Encode = new TextEncoder();
      const name = Array.from(utf16Encode.encode("TestMesg"));
      daat = daat.concat(name);
      console.log(daat);
      daat = this.encryptStringWithXORtoHex(daat, 0x32, 0x34);
      console.log(daat);
      cout.set(daat, 1);
      console.log(cout.slice(0.13), Buffer.from(cout.slice(0,13)));
      const crc = this.longToByteArray(this.localCrc16(Buffer.from(cout.slice(0,13))));
      cout.set(crc, 13);
      console.log(cout);
      const ret = await this.chw.writeValueWithResponse(cout);
      console.log(ret);
      const msg = this.decryptSrtingWithXORtoHex(ret, 0x32);
      this.key = msg[5];
    }
  }

  async BTUnlock(){

    if(this.chw){
      let cout = new Uint16Array(15);
      const cmd = [0xfe];
      cout.set(cmd,0);

      let daat = [0x34, 0x00, 0x21, 0x09];
      const user = [0x00,0x00,0x00,0x01]
      daat = daat.concat(user);
      const ts = [0x00,0x00,0x00,0x01]
      daat = daat.concat(ts);
      const ut = [0x01]
      daat = daat.concat(ut);
      console.log(daat);
      daat = this.encryptStringWithXORtoHex(daat, 0x32, 0x34);
      console.log(daat);
      cout.set(daat, 1);
      console.log(cout);
      const crc = this.longToByteArray(this.localCrc16(Buffer.from(cout)));
      cout.set(crc, 13);
      console.log(cout);
      const ret = await this.chw.writeValueWithResponse(cout);
      console.log(ret);
      const msg = this.decryptSrtingWithXORtoHex(ret, 0x32);
      this.key = msg[5];
    }
  }

  updateVehicleStatic(){
    this.e.getVehicleStatic(this.id).subscribe(data=>{
      this.static = data;
      if(this.static.model.iot && this.static.model.iot.use_ble)
        this.hasBLE = true;
      const fs = this.static.codes.filter(x=>x.context ==="flespi");
      if (fs.length === 1 ){
        this.vcode = fs[0].code;
        this.rt.subscribeVehicle(this.vcode).subscribe(data=>{
          this.rtdata = data;
        })
      }
    })
  }

  updateFleets(){
    this.e.getFleets({vehicle:this.id}).subscribe(data=>{
      this.fleets = data;
    })
  }

  updateAvailableCommands(){
    this.e.getAvailableCommands(this.static.protocol).subscribe(data=>{
    })
  }

  addCode(){
    const dialogRef = this.dialog.open(CodeEditorComponent, {
      width: '250px',
      data: {op: "Add"}
    });

    dialogRef.afterClosed().subscribe(result => {
      this.e.addCode(this.id, result.context, result.code.replace('#', "%23")).subscribe(data=>{
        this.updateVehicleCurrent();
        this.updateVehicleStatic();
      })
    });
  }

  
  addFleet(){
    const dialogRef = this.dialog.open(FleetEditorComponent, {
      width: '250px',
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, this.id);
      this.e.addToFleet(this.id, result.fleet).subscribe(data=>{
        this.updateFleets();
      })
    });
  }

  delFleet(fleetId){
    if(confirm('cancellare l\'associazione?')){
      this.e.delFromFleet(this.id, fleetId).subscribe(data=>{
        this.updateFleets()
      })
    }
  }

  qr(code){
    const dialogRef = this.dialog.open(QrcodeComponent, {
      width: '250px',
      data: {code}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  delete(){
    if(confirm('cancellare veicolo?')){
      this.e.deleteVehicle(this.id).subscribe(data=>{
        this.r.navigate(['..']);
      })
    }
  }

  edit(){
    
    const dialogRef = this.dialog.open(EditComponent, {
      width: '470px',
      data: {"mode": "Edit", vehicles:[{imei: this.static.imei, model: this.static.model.ident, fleet: this.static.fleets[0].ident}]}
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  search(){
    this.r.navigate(['/map', {vehicle: this.id}]);
  }

  navigate(){
    window.open('https://graphhopper.com/maps/?point=&point='+this.current.features[0].geometry.coordinates[1]+'%2C'+this.current.features[0].geometry.coordinates[0]+'&profile=car&layer=Omniscale')
  } 
  navigateG(){
    window.open('  https://www.google.com/maps/dir/?api=1&destination='+this.current.features[0].geometry.coordinates[1]+','+this.current.features[0].geometry.coordinates[0]+'&profile=car&layer=Omniscale')
  }

  editModel(mdoelId){
    
  }

  deleteCode(code){
    console.log(code);
    if(confirm('Delete code?')){
      this.e.deleteCode(this.id, code.context, code.code.replace('#', '%23')).subscribe(data=>{
        this.updateVehicleCurrent();
        this.updateVehicleStatic();
        this.s.open("deleted", "ok");
      })
    }
  }

  toggleFleet(fleet){

  }
  debug(vehicle){
    this.e.getVehicleCurrent(this.id).subscribe(data=>{
      console.log('current', data);
    });
    this.e.getVehicleStatic(this.id).subscribe(data=>{
      console.log('static',data);
    })
  }

  do_alert(code){
    alert(code.code);
  }

  createRental(){
    this.dialog.open(NewRentalComponent, {data: {vehicle:this.id}})
  }

}
