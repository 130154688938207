import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'edway-bookings-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() user: string;
  @Input() vehicle: string;

  public defaultColDef: ColDef = {
    editable: false,
    suppressMovable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };

  columnDefs: ColDef[] = [];

  list = [];
  show = false;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private modal: MatDialog,
    private tr: TranslateService
  ) {}

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    let ret = [];
    this.e
      .getBookings({ user: this.user, vehicle: this.vehicle })
      .subscribe((data: any[]) => {
        this.list = data;
        this.show = true;
      });

    this.columnDefs = [
      {
        field: 'user',
        headerName: this.tr.instant('bookings.columns.user'),
      },
      { field: 'vehicle', headerName: this.tr.instant('bookings.columns.vehicle') },
      { 
        field: 'booking_moment', 
        headerName: this.tr.instant('bookings.columns.booking_moment'),
        filter: 'agDateColumnFilter', },
      {
        field: 'booking_until',
        headerName: this.tr.instant('bookings.columns.booking_until'),
        filter: 'agDateColumnFilter',
      },
    ];
  }

  onRowClicked(ev) {
    console.log('log');
  }

}
