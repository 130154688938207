<mat-toolbar>
    <h1>Tags</h1>
    <span class="spacer"></span>
    <button mat-icon-button><mat-icon>edit</mat-icon></button>
</mat-toolbar>

<table style="width:100%">
    <tr>
        <th>Family</th>
        <th>Name</th>
        <th>Visible</th>
        <th>Icon</th>
        <th>Actions</th>
    </tr>
<tr
  *ngFor="let tag of tags"
>
<td><span class="item-tag tag-{{tag.family}}">{{ tag.family }}</span></td>
<td>{{ tag.name }}</td>
<td>{{ tag.visible }}</td>
<td><img src=""></td>
<td><button mat-icon-button><mat-icon>edit</mat-icon></button>
    <button mat-icon-button><mat-icon>delete</mat-icon></button></td>

</tr>
</table>