import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { EdwayService } from 'src/app/edway.service';
import { ImageboxComponent } from 'src/app/imagebox/imagebox.component';
import { LogdataComponent } from 'src/app/logdata/logdata.component';

@Component({
  selector: 'edway-pictures-log',
  templateUrl: './pictures.component.html',
  styleUrls: ['./pictures.component.scss']
})
export class PicturesComponent implements OnInit {
  filter = {
    to_time: new Date(Date.now() + 86400000).toISOString().split('T')[0],
    from_time: new Date(Date.now() - 86400000).toISOString().split('T')[0]
  }
  public defaultColDef: ColDef = {
    editable: false,
    sortable: false,
    flex: 1,
    minWidth: 100,
    filter: false,
    floatingFilter: false,
    resizable: true,
  };
  columnDefs: any[] = [

  ];
  logs: any[];
  show = false;
  

  @Input() vehicle: string;

  constructor(
    private ar: ActivatedRoute,
    private r: Router,
    private e: EdwayService,
    public dialog: MatDialog,
    private tr: TranslateService
  ) { }

  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();
    this.columnDefs = [
      { 
        field: 'created', 
        headerName: this.tr.instant('log.timestamp'), 
        filter: 'agDateColumnFilter' 
      },
      { 
        field: 'trip', 
        headerName: this.tr.instant('log.trip'),
      },
      { 
        field: 'user', 
        headerName: this.tr.instant('log.user'),
  }]
    this.updatelogs();
  }

  onRowClicked(e){
    console.log(e);
    const dialogRef = this.dialog.open(ImageboxComponent, {
      width: '400px',
      data: {img: e.data.url},
    });

    dialogRef.afterClosed().subscribe(result => {
    });

  }

  showTrack(){
    this.r.navigate(['/map', {track:this.vehicle}])
  }

  updatelogs(){
    console.log(this.filter);
    this.e.getVehiclePics(this.vehicle, this.filter.from_time, this.filter.to_time).subscribe((data:any[])=>{
      this.logs = data;
      this.show = true;
    })
  }


  filterlogs(){
    this.updatelogs();
  }

}
