<mat-toolbar>
    <span class="spacer"></span>
  </mat-toolbar>
  <div id="graph">
<mat-grid-list [cols]="3" [gutterSize]="20">
    <mat-grid-tile colspan="2" rowspan="2">
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
    <mat-grid-tile>
        <mat-grid-tile-footer>
            <span translate>statistics.vehicles</span>
        </mat-grid-tile-footer>
    </mat-grid-tile>
</mat-grid-list>
</div>