import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-bool-cell-renderer',
  templateUrl: './bool-cell-renderer.component.html',
  styleUrls: ['./bool-cell-renderer.component.scss']
})
export class BoolCellRendererComponent implements ICellRendererAngularComp {

  private params!: ICellRendererParams;
  public style!: any;
  public value!: boolean;
  public imgForMood!: string;

  constructor() { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    this.value = params.value;
  }

}


@Component({
  selector: 'app-rbool-cell-renderer',
  templateUrl: './rbool-cell-renderer.component.html',
  styleUrls: ['./bool-cell-renderer.component.scss']
})
export class ReverseBoolCellRendererComponent implements ICellRendererAngularComp {

  private params!: ICellRendererParams;
  public style!: any;
  public value!: boolean;
  public imgForMood!: string;

  constructor() { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    this.value = params.value;
  }

}
