import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { WalletListComponent } from './wallet-list/wallet-list.component';
import { GeneralComponent } from './general/general.component';
import { PackageEditComponent } from './package-edit/package-edit.component';
import { WalletEditComponent } from './wallet-edit/wallet-edit.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaymentsModule } from '../payments/payments.module';
import { SoldPackagesComponent } from './sold-packages/sold-packages.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    WalletListComponent,
    GeneralComponent,
    PackageEditComponent,
    WalletEditComponent,
    SoldPackagesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    PaymentsModule,
    AgGridModule,
    TranslateModule,
  ]
})
export class PackagesModule { }
