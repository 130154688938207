<h1 mat-dialog-title translate>users.adduser</h1>
<mat-dialog-content>
  <div class="column">
    <mat-form-field appearance="fill">
      <mat-label>Email (it will also be the username</mat-label>
      <input type="text" required matInput [(ngModel)]="data.email">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Phone number</mat-label>
      <input type="text" required matInput [(ngModel)]="data.phone_number">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Password</mat-label>
      <input type="password" required matInput [(ngModel)]="data.password">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>First Name</mat-label>
      <input type="text" matInput [(ngModel)]="data.first_name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Family Name</mat-label>
      <input type="text" matInput [(ngModel)]="data.family_name">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Birthdate</mat-label>
      <input type="text" matInput [(ngModel)]="data.birth_date">
    </mat-form-field>
    <mat-checkbox #mgr [(ngModel)]="data.is_mgr" rows="10">Manager</mat-checkbox>

    <mat-form-field [ngStyle]="{'visibility':data.is_mgr?'visible':'hidden'}" appearance="fill">
      <mat-label>For Fleet</mat-label>
      <mat-select [(ngModel)]="data.for_fleet">
        <mat-option *ngFor="let f of fleets" [value]="f.id">{{f.name}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" >{{'ops.cancel'|translate}}</button>
  <button mat-button (click)="createUser()">{{'ops.ok'|translate}}</button>
</mat-dialog-actions>
