import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-wh-add',
  templateUrl: './wh-add.component.html',
  styleUrls: ['./wh-add.component.scss']
})
export class WhAddComponent implements OnInit {

  events = [];
  targets = [];
  transformations = [];

  data: any = {
    "sources": "all",
    "sources_list":{},
    "events": "all",
    "events_list":{},
    "transformation": "hom",
    "target": "custom",
    "target_details": {
      "mode":"",
      "url": ""
    },
  }

  constructor(
    private e: EdwayService, 
    public dialogRef: MatDialogRef<WhAddComponent>,

  ) { }

  ngOnInit(): void {
    this.e.getCloudPlatformEvents().subscribe((d:any)=>{
      this.events = d.items;
    })
    
    this.e.getCloudTargets().subscribe((d:any)=>{
      this.targets = d.items;
    })
    
    this.e.getCloudTransformations().subscribe((d:any)=>{
      this.transformations = d.items;
    })
  }

  createWH(data){
    this.e.createCloudWebhook(data).subscribe(d=>{
      this.dialogRef.close();
    })
  }

}
