<h1 mat-dialog-title>Add Role</h1>
<div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Role</mat-label>
      <select matNativeControl required [(ngModel)]="data.reason">
        <option value="FREEUSE">Free use customer</option>
        <option value="ADMIN">Administrator (sees all data)</option>
        <option value="MULTITRIP">Can unlock multiple vehicles</option>
      </select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Annulla</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
