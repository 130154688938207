import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { CacheService } from '../cache.service';

@Component({
  selector: 'app-product-renderer',
  templateUrl: './product-renderer.component.html',
  styleUrls: ['./product-renderer.component.scss']
})
export class ProductRendererComponent implements ICellRendererAngularComp {

  private params!: ICellRendererParams;
  public style!: any;
  public value!: string;
  public imgForMood!: string;

  constructor(
    private c: CacheService
  ) { }
  refresh(params: ICellRendererParams): boolean {
    this.params = params;
    this.setMood(params);
    return true;
  }
  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.setMood(params);  }

  private setMood(params: ICellRendererParams) {
    this.value = this.c.get('products',params.value);
  }

}