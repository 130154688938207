import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'code'
})
export class CodePipe implements PipeTransform {

  transform(value: {context:string, code: string}, ...args: unknown[]): unknown {
    if(value.context == 'flespi'){
      return "<a href='https://flespi.io/#/panel/list/devices/"+value.code+"' target='_blank'>"+value.code+" </a>";
    } else {
      return value.code;
    }
  }

}
