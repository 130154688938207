import { Component, OnInit } from '@angular/core';
import { EdwayService } from '../edway.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  files: any[]= [];

  constructor(
    private e: EdwayService,
    private _sb: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.updateList();
  }

  updateList(){
    
    this.e.getFiles().subscribe((data:any)=>{
      this.files = [{
        "type" :"invoice",
        "emitted": "2024-04-01",
        "paid": "2024-04-02",
        "url": "#",
        "amount" : 128,
        "currency": "EUR"
      },{
        "type" :"invoice",
        "emitted": "2024-05-01",
        "paid": null,
        "url": "#",
        "amount" : 128,
        "currency": "EUR"
      }];
    })
  }

}
