import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { DetailComponent } from './detail/detail.component';
import { SharedModule } from '../shared.module';
import { AgGridModule } from 'ag-grid-angular';
import { AddComponent } from './add/add.component';
import { AmendComponent } from './amend/amend.component';
import { AmendDialogComponent } from './amend-dialog/amend-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { ExporterComponent } from './exporter/exporter.component';



@NgModule({
  declarations: [
    ListComponent,
    DetailComponent,
    AddComponent,
    AmendComponent,
    AmendDialogComponent,
    ExporterComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    TranslateModule
  ],
  exports:[
    ListComponent,
    DetailComponent
  ]
})
export class PaymentsModule { }
