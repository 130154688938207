import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {

  reasons = [
    "no_payment",
    "ticket",
    "bad_conduct",
    "sanction"
  ]

ldata = {
  reason: null,
  amount: null,
  description: null,
  trip: ''
};

  constructor(
    public dialogRef: MatDialogRef<AddComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private e: EdwayService

  ) { }

  ngOnInit(): void {
    console.log('injected', this.data);
    this.ldata.trip = this.data.trip.id;
  }

  submit(){
    console.log(this.ldata);
    this.e.createPayment(this.ldata).subscribe(rdata=>{
      this.dialogRef.close(rdata);
    })
  }

  cancel(){
    this.dialogRef.close();
  }

}
