<h1 mat-dialog-title>{{data.op|translate}} <span translate>vehicles.edit.title</span> <button *ngIf="data.op==='Add'" mat-icon-button (click)="addRow()"><mat-icon>add</mat-icon></button></h1>
<div mat-dialog-content (paste)="onPaste($event)">
  <table>
    <tr *ngFor="let vehicle of data.vehicles">
      <td>
        <mat-form-field appearance="fill">
          <mat-label translate>vehicles.edit.imei</mat-label>
          <input matInput [(ngModel)]="vehicle.imei">
        </mat-form-field>

      </td>
      <td>
        <mat-form-field appearance="fill">
          <mat-label translate>vehicles.edit.model</mat-label>
          <select [disabled]="mode === 'paste'" matNativeControl required [(ngModel)]="vehicle.model">
            <option *ngFor="let model of models" value="{{model.ident}}">{{model.name}} ({{model.middleware}})</option>
          </select>
        </mat-form-field>

      </td>
      <td *ngIf="data.op === 'Add'">
        <mat-form-field appearance="fill">
          <mat-label translate>vehicles.edit.fleet</mat-label>
          <select [disabled]="mode === 'paste'" matNativeControl required [(ngModel)]="vehicle.fleet">
            <option *ngFor="let fleet of fleets" value="{{fleet.id}}">{{fleet.name}}</option>
          </select>
        </mat-form-field>
      </td>
      <td *ngIf="data.op === 'Add'">
        <div>{{vehicle.extra|json}}</div>
      </td>
    </tr>
  </table>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()" >{{'ops.cancel'|translate}}</button>
  <button mat-button (click)="addVehicles(data)" [mat-dialog-close]="data" cdkFocusInitial>{{'ops.ok'|translate}}</button>
</div>
