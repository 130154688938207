<h1 mat-dialog-title translate>vehicles.add.fleet</h1>
<div mat-dialog-content>
    <mat-form-field appearance="fill">
      <mat-label>{{'vehicles.detail.fleets'|translate}}</mat-label>
      <select matNativeControl required [(ngModel)]="data.fleet">
        <option *ngFor="let fleet of fleets" value="{{fleet.id}}">{{fleet.name}}</option>
      </select>
    </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">{{'op.cancel'|translate}}</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
