import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';

@Component({
  selector: 'app-add-area-setting',
  templateUrl: './add-area-setting.component.html',
  styleUrls: ['./add-area-setting.component.scss']
})
export class AddAreaSettingComponent implements OnInit {

  data = {
    key: "",
    value: ""
  }

  constructor(
    private e: EdwayService,
    public dialogRef: MatDialogRef<AddAreaSettingComponent>,
    @Inject(MAT_DIALOG_DATA) public adata: {fleet:string, area:string, pagemode:string}
  ) { }

  ngOnInit(): void {
  }

  save(){
    this.e.addAreaSetting(this.adata.fleet, this.adata.area, this.data.key, this.data.value).subscribe((data)=>{
      this.dialogRef.close({
        fleet: this.adata.fleet,
        area: this.adata.area,
        key: this.data.key,
        value: this.data.value
      });
    })
  }

}
