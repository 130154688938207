import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EdwayService } from 'src/app/edway.service';
import { SettingsService } from 'src/app/settings.service';

declare const mapboxgl: any;
declare const maplibre: any;

@Component({
  selector: 'app-navigate',
  templateUrl: './navigate.component.html',
  styleUrls: ['./navigate.component.scss']
})
export class NavigateComponent implements OnInit {

  map: any;
  mapbox_key;

  nav:any = {};
  
  step = 0; 
  indication = "";
  length = 0;
  stepdata:any = {};

  nearby: any = {};
  datetime: string = null;
  user: string = "";
  battery: number = 100;

  constructor(
    private e: EdwayService, 
    private s: SettingsService,
    public dialogRef: MatDialogRef<NavigateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.datetime = new Date().toISOString();
    this.s.settings$.subscribe((settingsOk: boolean) => {
      if (settingsOk) {
        this.mapbox_key = this.s.get('mapbox_key');
        mapboxgl.accessToken =
          'pk.eyJ1Ijoic2lybW1vIiwiYSI6ImNsM3R2djh5ajBjdTgzam52NDBvZzVhcXEifQ.wSyHLLyQ7X5SmSXHLw5clA';
        this.initMap();
      }
    });

  }
  

  initMap() {
    this.map = new mapboxgl.Map({
      container: 'navmap', // container ID
      style: 'mapbox://styles/mapbox/streets-v11', // style URL
      center: [11.360105, 41.754922], // starting position [lng, lat]
      zoom: 20, // starting zoom
      pitch: 60
    });

    this.map.on('load', ()=>{
      
          this.e.getPoiNavigation(this.data.id.id).subscribe(data=>{
            this.nav = data;
      
            this.map.addSource('nav', {
              type:"geojson", 
              data:data
            });
            this.map.addLayer({
              'id': 'nav',
              'type': 'line',
              'source': 'nav',
              'filter': ['==', '$type', 'LineString'],
              'paint': {
                  'line-color': '#888888',
                  'line-opacity': 0.7,
                  'line-width': 2
              }
          });
          this.map.addLayer({
            'id': 'nav-p',
            'type': 'circle',
            'source': 'nav',
            'filter': ["all", ['==', '$type', 'Point'], ["==", "type", "direction"]],
            'paint': {
                'circle-color': '#888888',
                'circle-opacity': 0.7,
                'circle-radius': 4
            }
          });
          this.map.addLayer({
            'id': 'nav-pois',
            'type': 'circle',
            'source': 'nav',
            'filter': ["all", ['==', '$type', 'Point'], ["==", "type", "poi"]],
            'paint': {
                'circle-color': '#ff0000',
                'circle-opacity': 0.7,
                'circle-radius': 10
            }
          });
          this.length = this.nav.features.length -1;
          this.nextStep();
        });
    });
}

  nextStep(step=1){
    if (this.step < this.length){

      if (this.step > 0)
      this.map.rotateTo(this.nav.features[this.step].properties['maneuver.bearing_after'], {duration: 500})
      this.step +=step;
      this.map.panTo(this.nav.features[this.step].geometry.coordinates, {duration: 3000});
      this.map.rotateTo(this.nav.features[this.step].properties['maneuver.bearing_before'], {duration: 500})
      this.indication = this.nav.features[this.step].properties['verbose'];
      this.stepdata = this.nav.features[this.step].properties;
      this.e.getPoiNearby(this.nav.features[this.step].geometry.coordinates, this.datetime, this.user, this.battery).subscribe((data:any)=>{
        this.nearby = data;
      })
    }
  }
}
