


import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ColDef } from 'ag-grid-community';
import { BoolCellRendererComponent } from '../../bool-cell-renderer/bool-cell-renderer.component';
import { EdwayService } from '../../edway.service';
import { ProductRendererComponent } from '../../product-renderer/product-renderer.component';

@Component({
  selector: 'app-sold-packages',
  templateUrl: './sold-packages.component.html',
  styleUrls: ['./sold-packages.component.scss']
})
export class SoldPackagesComponent implements OnInit {
  @Input() user: string;
  public defaultColDef: ColDef = {
    editable: true,
    sortable: true,
    flex: 1,
    minWidth: 100,
    filter: true,
    floatingFilter: true,
    resizable: true,
  };
  columnDefs: ColDef[] = [];

  rowData = [];
  show = true;
  constructor(
    private e: EdwayService,
    private r: Router,
    private ar: ActivatedRoute,
    private tr: TranslateService
  ) {}


  dateFormatter(params) {
    if(params.value){
      var dateTimeAsString = params.value;
      var dateAsString = dateTimeAsString.split("T")[0];
      var timeAsString = dateTimeAsString.split("T")[1];
      var dateParts = dateAsString.split('-');
      var timeParts = timeAsString.split(':');
      return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]} ${timeParts[0]}:${timeParts[1]}`;
    } else {
      return '';
    }
  }

  boolFormatter(params){
    if(params.value === undefined){
      return '';
    } else {
      if (params.value)
        return '✅';
      else
        return '⛔';
    }
  }

  secondsFormatter(params){
    if(params.value === undefined){
      return '';
    } else {
      const secs = params.value%60;
      const mins = ((params.value-secs)/60)%60;
      const hrs = (((params.value-secs)/60)-mins)%24;
      return `${hrs}h ${mins}m ${secs}s`;
    }
  }
  async ngOnInit() {
    await this.tr.get('dummyTranslation').toPromise().then();

    this.e.refreshPackages.subscribe((x) => {
      this.refresh();
    });
    this.refresh();

    this.columnDefs = [
      {
        headerName: this.tr.instant('users.packages.package'),
        field: 'package',
        resizable: true,
        cellRenderer: ProductRendererComponent,
      },
      {
        headerName: this.tr.instant('users.packages.acquired'),
        field: 'acquired',
        resizable: true,
        valueFormatter: this.dateFormatter
      },
      {
        headerName: this.tr.instant('users.packages.can_activate'),
        field: 'can_activate',
        resizable: true,
        valueFormatter: this.dateFormatter
      },
      {
        headerName: this.tr.instant('users.packages.activated'),
        field: 'activated',
        resizable: true,
        valueFormatter: this.dateFormatter
      },
      {
        headerName: this.tr.instant('users.packages.active'),
        field: 'active',
        resizable: true,
        valueFormatter: this.boolFormatter
      },
      {
        headerName: this.tr.instant('users.packages.remaining_time'),
        field: 'remaining_time',
        resizable: true,
        valueFormatter: this.secondsFormatter
      },
      {
        headerName: this.tr.instant('users.packages.remaining_unlocks'),
        field: 'remaining_unlocks',
        resizable: true,
      },
      {
        headerName: this.tr.instant('users.packages.exhausted'),
        field: 'exhausted',
        resizable: true,
        valueFormatter: this.boolFormatter
      },
    ];
    if (!this.user){
      this.columnDefs.unshift({
        headerName: this.tr.instant('users.packages.user'),
        field: 'user',
      })
    }
  }

  refresh() {
    let ret = [];
    this.e.getPackages({user:this.user}).subscribe((data: any[]) => {
      //data.sort(orderPackage);

      this.rowData = data;
      this.show = true;
    });
  }

  onRowClicked(ev) {
    this.r.navigate(['/users', ev.data.ident]);
  }
}

function orderPackage(a, b) {
  // attivi e non exausted
  // non attivi
  // exausted
  return a.exhausted - b.exhausted || b.active - a.active;
}
