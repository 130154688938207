import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main/main.component';
import { SharedModule } from '../shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { PoiComponent } from './poi/poi.component';
import { ExperienceComponent } from './experience/experience.component';
import { ItineraryComponent } from './itinerary/itinerary.component';
import { PackageComponent } from './package/package.component';
import { ListItinerariesComponent } from './list-itineraries/list-itineraries.component';
import { ListPackagesComponent } from './list-packages/list-packages.component';
import { ListExperiencesComponent } from './list-experiences/list-experiences.component';
import { ListTagsComponent } from './list-tags/list-tags.component';
import { ListPoisComponent } from './list-pois/list-pois.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavigateComponent } from './navigate/navigate.component';

@NgModule({
  declarations: [
    MainComponent,
    PoiComponent,
    ExperienceComponent,
    ItineraryComponent,
    PackageComponent,
    ListItinerariesComponent,
    ListPackagesComponent,
    ListExperiencesComponent,
    ListTagsComponent,
    ListPoisComponent,
    NavigateComponent,
  ],
  imports: [CommonModule, SharedModule, TranslateModule, DragDropModule],
  exports: [],
})
export class ExperiencesModule {}
